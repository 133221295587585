<template>
  <div class="page">
    <!-- 导航 -->
    <nav class="nav-bar">
      <van-icon
        name="arrow-left"
        class="icon-left"
        size="20"
        color="#9B9B9B"
        @click="lastStep"
      />
      <span class="title-text">{{
        editStatus === "edit" ? "编辑闲置" : "发布闲置"
      }}</span>
      <div class="button">
        <van-button
          round
          block
          size="small"
          color="linear-gradient(to right, #ED301D, #FE5876)"
          @click="debounceFn"
        >
          {{ editStatus === "edit" ? "保存" : "发布" }}
        </van-button>
      </div>
    </nav>
    <!-- 表单 -->
    <van-form>
      <!-- 文本域 -->
      <div>
        <van-field
          v-model="goodsData.used_name"
          rows="2"
          autosize
          :border="false"
          type="textarea"
          maxlength="300"
          placeholder="请填写闲置产品信息及转手原……"
          show-word-limit
          class="textarea"
        />
      </div>
      <!-- 文件上传 -->
      <div class="files-upload">
        <OssImg @getImages="getImages" :emptyImg="emptyImg"></OssImg>
      </div>
      <div class="from-select">
        <!-- input -->
        <div class="area-select" @click="openPopup">
          <span class="lable-text">发布区域</span>
          <div class="address-text">
            <van-icon
              name="location-o"
              size="20"
              color="#9B9B9B"
              class="location"
            />
            <span class="region">{{ location || "请选择发布区域" }}</span>
            <van-icon name="arrow" size="20" color="#9B9B9B" class="select" />
          </div>
        </div>
        <div class="input-box">
          <span class="lable-text">售价</span>
          <input
            type="number"
            v-model="goodsData.price"
            placeholder="请输入转手价格"
            class="input"
          />
        </div>
        <div class="input-box">
          <span class="lable-text">原价</span>
          <input
            type="number"
            v-model="goodsData.original_price"
            placeholder="请输入购买原价"
            class="input"
          />
        </div>
        <!-- 发货方式 -->
        <div class="deliver-goods">
          <span class="lable-text">发货方式</span>
          <van-radio-group v-model="goodsData.deliver_type" class="radio-box">
            <van-radio
              :name="1"
              class="radio"
              @click="changeRadio(goodsData.deliver_type)"
            >
              <span :class="{ 'radio-back': goodsData.deliver_type === 1 }">
                包邮
              </span>
            </van-radio>
            <van-radio
              :name="2"
              class="radio"
              @click="changeRadio(goodsData.deliver_type)"
            >
              <span :class="{ 'radio-back': goodsData.deliver_type === 2 }">
                到付
              </span>
            </van-radio>
            <van-radio
              :name="3"
              class="radio"
              @click="changeRadio(goodsData.deliver_type)"
            >
              <span :class="{ 'radio-back': goodsData.deliver_type === 3 }">
                自取
              </span>
            </van-radio>
          </van-radio-group>
        </div>
        <div class="input-box" v-if="goodsData.deliver_type === 3">
          <span class="lable-text">联系电话</span>
          <input
            type="number"
            v-model="goodsData.phone"
            placeholder="请输入联系电话"
            class="input"
          />
        </div>
        <div class="input-box" v-if="goodsData.deliver_type === 3">
          <span class="lable-text">详细地址</span>
          <input
            type="text"
            v-model="goodsData.address"
            placeholder="请选择详细地址"
            class="input"
          />
          <van-icon
            name="location-o"
            size="20"
            color="#9B9B9B"
            class="orientation"
            @click="addressFocus"
          />
        </div>
        <!-- 上架 -->
        <div class="is-putaway">
          <span class="lable-text">上架</span>
          <van-switch
            v-model="goodsData.status"
            active-color="#ee0a24"
            inactive-color="#dcdee0"
            size="20"
            :active-value="1"
            :inactive-value="0"
          />
        </div>
      </div>
    </van-form>
    <CascaderPopup
      :isShow="showPopup"
      @setLocationInfo="setLocationInfo"
    ></CascaderPopup>
    <MapSearch
      v-if="showMap"
      :lat="goodsData.lat"
      :lon="goodsData.lon"
      @setDetailAddress="setDetailAddress"
      @endSearch="endSearch"
      class="map-search"
    ></MapSearch>
    <TxMap ref="txMap" @setLocation="setLocation"></TxMap>
  </div>
</template>

<script>
import TxMap from "@/components/map/tx-map";
import MapSearch from "@/components/map/mapSearch";
import CascaderPopup from "@/components/cascader-popup/cascader-popup";
import OssImg from "@/components/oss/oss-img";
import { setPublishGoods, getGoodsDetails } from "@/api/used";
import { Toast } from "vant";
import { debounce } from "lodash";
export default {
  name: "publish-used",
  components: {
    CascaderPopup,
    OssImg,
    MapSearch,
    TxMap,
  },
  data() {
    return {
      showMap: false,
      checked: true,
      showPopup: false,
      editStatus: this.$route.query.editStatus,
      goodsData: {
        used_name: "",
        province_id: "", //省
        city_id: "", //市
        area_id: "", //区
        original_price: "",
        price: "",
        imgs: [],
        // used_imgs: "",
        deliver_type: 1,
        lon: 106.55034,
        lat: 29.56208,
        // address: "",
        // phone: "",
        status: 1,
        id: this.$route.query.goodsId || 0,
      },
      emptyImg: [],
      location: "",
      debounceFn: null,
      flag: false,
    };
  },
  created() {
    this.debounceFn = debounce(this.publishGoods, 500);
  },
  mounted() {
    console.log();
    if (this.goodsData.id > 0) {
      this.getGoodsDetails();
    }
  },
  methods: {
    endSearch() {
      this.showMap = false;
    },
    addressFocus() {
      this.showMap = true;
    },
    setLocation(location) {
      this.goodsData.address = location.addr;
      this.goodsData.lat = location.lat;
      this.goodsData.lon = location.lng;
    },
    setDetailAddress(location) {
      // console.log(location);
      // this.detailAddress=location.poiaddress+' '+location.poiname;
      this.goodsData.address = location.poiname;
      this.goodsData.lon = location.latlng.lng;
      this.goodsData.lat = location.latlng.lat;
      console.log(this.goodsData.address);
      this.showMap = false;
    },
    getImages(imgArr) {
      this.goodsData.imgs = imgArr;
    },
    publishGoods() {
      // 发布商品
      // this.checked ? (this.goodsData.status = 1) : (this.goodsData.status = 0);
      if (this.verification()) {
        this.setPublishGoods();
      }
    },
    openPopup() {
      this.showPopup = true;
    },
    setLocationInfo(locationObj, location) {
      this.goodsData = { ...this.goodsData, ...locationObj };
      this.location = location;
      this.showPopup = false;
    },
    changeRadio(name) {
      // 判定是否自提
      if (name === 3) {
        this.goodsData.address = "";
        this.goodsData.phone = "";
      } else {
        delete this.goodsData.address;
        delete this.goodsData.phone;
      }
    },
    verification() {
      //表单验证
      if (this.goodsData.used_imgs === []) {
        Toast.fail("图片正在上传");
        return false;
      }
      // console.log("验证数据==================", this.goodsData);
      for (const key in this.goodsData) {
        if (Array.isArray(this.goodsData[key])) {
          if (JSON.stringify(this.goodsData[key]) === "[]") {
            Toast.fail("请上传商品图片");
            return false;
          }
        } else {
          if (this.goodsData[key] === "") {
            Toast.fail("必填项不能为空");
            return false;
          }
        }
      }
      return true;
    },
    async setPublishGoods() {
      // 发布/获取闲置
      // console.log("------请求前的数据--------", this.goodsData);
      if (this.flag) {
        return false;
      }
      this.flag = true;
      const res = await setPublishGoods(this.goodsData);
      if (res.code === 1) {
        Toast.success("发布成功");
        this.emptyForm();
        this.emptyImg = [];
        this.$router.replace({ name: "my-used" });
      } else {
        Toast.fail(res.msg);
        this.flag = false;
      }
    },
    async getGoodsDetails() {
      // 获取商品详情
      const res = await getGoodsDetails({ id: this.goodsData.id, type: 2 });
      // console.log("请求数据======================", res);
      this.goodsData = res.data;
      this.location = res.data.city_name;
      res.data.imgs.forEach((item) => {
        this.emptyImg.push({ url: item });
      });
      console.log("判定自提", res.data.deliver_type !== 3);
      if (res.data.deliver_type !== 3) {
        delete this.goodsData.address;
        delete this.goodsData.phone;
      }
    },
    lastStep() {
      this.$router.go(-1);
    },
    emptyForm() {
      // 清空表单
      this.goodsData = {
        used_name: "",
        province_id: "", //省
        city_id: "", //市
        area_id: "", //区
        original_price: "",
        price: "",
        used_imgs: "",
        deliver_type: 1,
        status: 1,
        id: 0,
        lon: 106.55034,
        lat: 29.56208,
      };
    },
  },
};
</script>

<style lang="less" scoped>
@heightLine: 58px; //导航  title高度
@boxborder:1px solid #F8F7FC; //表单边框
@inputHeight: 48px; //表单输入框高
.page {
  min-height: 100vh;
  box-sizing: border-box;
}
.nav-bar {
  position: relative;
  border-bottom: @boxborder;
  height: @heightLine;
  text-align: center;
  background-color: #fff;
  .title-text {
    font-size: 21px;
    line-height: @heightLine;
  }
  .icon-left {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
  }
  .button {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    width: 62px;
  }
}
/deep/.van-field__control {
  font-size: 16px;
  min-height: 97px !important;
}
.files-upload {
  padding: 0 12px;
  background-color: #fff;
}
.from-select {
  margin-top: 12px;
  // padding: 12px;
  padding: 0 12px 12px 12px;
  background-color: #fff;
  .area-select {
    display: flex;
    justify-content: space-between;
    // border-top: @boxborder;
    box-sizing: border-box;
    padding: 12px 0;
    .address-text {
      position: relative;
      padding: 0 22px;
      .location {
        position: absolute;
        top: 2px;
        left: 0;
      }
      .select {
        position: absolute;
        top: 2px;
        right: 0;
      }
    }
    .region {
      font-size: 17px;
    }
  }
  .input-box {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: @inputHeight;
    border-top: @boxborder;
    .input {
      border: none;
      outline: none;
      font-size: 17px;
    }
    input::input-placeholder {
      color: #aaa;
    }
    input::-webkit-input-placeholder {
      color: #aaa;
    }
    input::-moz-placeholder {
      color: #aaa;
    }
    input:-moz-placeholder {
      color: #aaa;
    }
    input::-ms-input-placeholder {
      color: #aaa;
    }
  }
  .lable-text {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    display: block;
    font-size: 17px;
    width: 72px;
    margin-right: 20px;
    font-weight: 500;
  }
}
.deliver-goods {
  height: @inputHeight;
  display: flex;
  align-items: center;
  border-top: @boxborder;
  box-sizing: border-box;
  .radio-box {
    display: flex;
    .radio {
      margin-right: 20px;
      font-size: 16px;
    }
    .radio-back {
      color: #000 !important;
    }
  }
}
/deep/.van-radio__label {
  color: #aaaaaa;
}
.is-putaway {
  display: flex;
  align-items: center;
  height: @inputHeight;
  box-sizing: border-box;
  border-top: @boxborder;
  border-bottom: @boxborder;
}
.tx-map {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
}
.van-button__text {
  font-size: 17px;
}
.orientation {
  margin-left: 30px;
}
/deep/ .van-radio__icon--checked .van-icon {
  background: linear-gradient(to bottom, #f8757e, #f51e07);
  border-color: #f8757e;
}
</style>
